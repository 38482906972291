import {Button, Link as MuiLink, Stack, Typography} from '@mui/material';
import Image from './Image';
import {useNavigate} from 'react-router-dom';

const openEventRegistration = () => {
  window.open('https://secure.fundeasy.com/ministrysync/event/?e=29552');
};

const Home = () => {
  const navigate = useNavigate();

  return <Stack alignItems="center">
    <Image
      sx={{maxWidth: '100vw', cursor: 'pointer'}}
      src="2025_invitation_front.jpg"
      alt="A kid wearing a pilot outfit and pretending to fly"
      onClick={openEventRegistration}
    />
    <Typography variant="h3" fontFamily="Dancing Script" textAlign="center" mt={2} ml={2}>Join Us!</Typography>
    <Typography ml={2} mr={2} textAlign="center" maxWidth={1000}> 
      The Sophia Women's Center Vision Night is a night celebrating God's faithfulness to the mission of giving women hope. We invite you to hear and celebrate with us. There will be an opportunity to give a financial donation or pledge. Please register by March 28th.<br/><br/>
      <strong>April 8, 2025</strong><br/>
      6:00pm - Hors d'oeuvres and Fellowship<br/>
      7:00pm - Presentation and Celebration<br/>
      <MuiLink href="https://maps.app.goo.gl/wNLiJXX8Dt95ZHQ88" target="_blank" rel="noopener">
        Friends Church<br/>
        28857 Walnut St<br/>
        Damascus, OH 44619
      </MuiLink>
    </Typography>
    <Stack direction="row" justifyContent="center" sx={{p: 2}}>
    <Button
        sx={{mr: 1}}
        color="primary"
        variant="contained"
        onClick={openEventRegistration}
      >
        Register Now!
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          navigate('/support');
        }}
      >
        Donate Online!
      </Button>
    </Stack>
  </Stack>
};

export default Home;
